<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/module/push" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.push.title") }}
        </router-link>
    </div>
    <custom-table
        :title="$t('pages.module.push.notification.title')"
        :subTitle="$t('pages.module.push.notification.subTitle')"
        rowKey="id"
        :items="pushTableData"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:scheduledDate="{ row: record }">
            {{ record.scheduled_date ? $moment(record.scheduled_date).format("DD.MM.YYYY - HH:mm:ss") : "-" }}
        </template>
        <template v-slot:status="{ row: record }">
            <span v-if="record.status && record.status.code" :class="pushStatus[record.status.code.toLocaleLowerCase()] ? pushStatus[record.status.code.toLocaleLowerCase()].badge : 'badge-warning'" class="badge">{{ record.status.name }}</span>
            <span v-else>-</span>
        </template>
        <template v-slot:successful="{ row: record }">
            {{ record.provider_statistics ? record.provider_statistics.successful : "-" }}
        </template>
        <template v-slot:createdAt="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" v-on:click="showRecord(record)">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_notification" ref="notificationModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="notificationForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                             data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.push.notification.cols.provider') }}</label>
                                <el-form-item prop="provider_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.provider_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(provider, providerIndex) in providers" :key="providerIndex" :value="provider.id" :label="provider.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.push.notification.cols.pushData.title') }}</label>
                                <el-form-item prop="push_data.title" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.push_data.title" type="text"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.push.notification.cols.pushData.message') }}</label>
                                <el-form-item prop="push_data.message" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.push_data.message" type="textarea" rows="3"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.push.notification.cols.pushData.image') }}</label>
                                <el-form-item prop="push_data.image_id">
                                    <CustomImage :handleImage="form.data.poster" @updateImage="image = $event"></CustomImage>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.push.notification.cols.pushData.launchUrl') }}</label>
                                <el-form-item prop="push_data.launch_url">
                                    <el-input v-model="form.data.push_data.launch_url" type="text"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.push.notification.cols.scheduleDate') }}</label>
                                <el-form-item prop="scheduled_date">
                                    <el-date-picker v-model="form.data.scheduled_date" type="date" :placeholder="$t('common.chooseDate')" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" clearable></el-date-picker>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_notification_detail" ref="notificationDetailModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_notification_detail_header">
                    <h2 class="fw-bolder">{{ $t('pages.module.push.notification.show') }}</h2>
                    <div id="kt_modal_reservation_detail_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_notification_detail_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <div class="fv-row mb-3 d-flex" v-if="form.data.provider">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.push.notification.cols.provider') }}:</label>
                            <span class="text-gray-600 fw-bold fs-6">{{ form.data.provider.name }}</span>
                        </div>
                        <template v-if="form.data.push_data">
                            <div class="fv-row mb-3">
                                <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.push.notification.cols.pushData.title') }}:</label>
                                <span class="text-gray-600 fw-bold fs-6">{{ form.data.push_data.title }}</span>
                            </div>
                            <div class="fv-row mb-3">
                                <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.push.notification.cols.pushData.message') }}:</label>
                                <span class="text-gray-600 fw-bold fs-6">{{ form.data.push_data.message }}</span>
                            </div>
                            <div class="fv-row mb-3">
                                <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.push.notification.cols.pushData.image') }}:</label>
                                <a v-if="form.data.image" class="poster-image-show" :href="form.data.image.public_url" target="_blank">
                                    <div class="poster-image symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                        <img  :src="form.data.image.thumb.small" alt="image">
                                        <span class="poster-image-overlay">
                                            <span class="poster-image-preview"><i class="bi bi-search"></i></span>
                                        </span>
                                    </div>
                                </a>
                                <span v-else class="text-gray-600 fw-bold fs-6">-</span>
                            </div>
                            <div class="fv-row mb-3">
                                <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.push.notification.cols.pushData.launchUrl') }}:</label>
                                <span class="text-gray-600 fw-bold fs-6">{{ form.data.push_data.launch_url ?? "-" }}</span>
                            </div>
                        </template>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.push.notification.cols.scheduleDate') }}:</label>
                            <span class="text-gray-600 fw-bold fs-6">{{ form.data.scheduled_date ? $moment(form.data.scheduled_date).format("DD.MM.YYYY - HH:mm:ss") : "-" }}</span>
                        </div>
                        <div class="fv-row mb-3 d-flex align-items-center">
                            <label class="fs-5 fw-bold mb-0 me-2">{{ $t('pages.module.push.notification.cols.providerStatistics.successful') }}:</label>
                            <span class="text-gray-600 fw-bold fs-6">{{ form.data.provider_statistics ? form.data.provider_statistics.successful : "-" }}</span>
                        </div>
                        <div class="fv-row mb-3 d-flex align-items-center" v-if="form.data.status">
                            <label class="fs-5 fw-bold mb-0 me-2">{{ $t('common.status') }}:</label>
                            <span :class="pushStatus[form.data.status.code.toLocaleLowerCase()] ? pushStatus[form.data.status.code.toLocaleLowerCase()].badge : 'badge-warning'" class="badge">{{ form.data.status.name }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-0 me-2">{{ $t('common.createdAt') }}:</label>
                            <span class="text-gray-600 fw-bold fs-6">{{ $moment(form.data.created_at).format("DD.MM.YYYY - HH:mm:ss") }}</span>
                        </div>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.notificationDetailModal)">{{ $t("btn.close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
import CustomImage from "@/components/custom-image";

export default {
    name: "index",
    components: {
        CustomTable,
        CustomImage
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.push.notification.cols.provider"),
                    key: "provider.name"
                },
                {
                    name: this.$t("pages.module.push.notification.cols.pushData.title"),
                    key: "push_data.title"
                },
                {
                    name: this.$t("pages.module.push.notification.cols.scheduleDate"),
                    scopedSlots: {customRender: "scheduledDate"}
                },
                {
                    name: this.$t("pages.module.push.notification.cols.providerStatistics.successful"),
                    scopedSlots: {customRender: "successful"}
                },
                {
                    name: this.$t("common.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.createdAt"),
                    scopedSlots: {customRender: "createdAt"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {
                    push_data: {}
                }
            },
            image: {
                uploadList: [],
                fileList:[]
            },
            selectedRowKeys: [],
            pushStatus: {
                pending: {
                    code: 'pending',
                    badge: 'badge-primary'
                },
                processing: {
                    code: 'processing',
                    badge: 'badge-warning'
                },
                sent: {
                    code: 'sent',
                    badge: 'badge-success'
                },
                error: {
                    code: 'error',
                    badge: 'badge-danger'
                }
            }
        }
    },
    computed: {
        table() {
            return this.$store.state.module.push.notification.table;
        },
        pushTableData(){
            return this.table.data.map((notification) => {
                notification.status = this.resolveDatum(this.status, notification.status_id);
                return notification;
            });
        },
        providers() {
            return this.$store.state.module.push.provider.table.data;
        },
        status() {
            return this.$store.state.module.push.status.table.data;
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.push.notification.title"), [this.$t("menu.moduleManagement"), this.$t("pages.module.push.title")]);

        this.$store.dispatch('module/push/notification/get', {
            page: {},
            filterData: {
                sort: "created_at:desc"
            }
        });

        this.$store.dispatch('module/push/status/get', {
            page: { pageSize: 9999 }
        });

        this.$store.dispatch('module/push/provider/get', {
            page: { pageSize: 9999 }
        });
    },
    methods: {
        newRecord() {
            this.form.updateStatus = false;
            this.form.data = {
                push_data: {}
            };
            this.image = {uploadList: [], fileList: []};

            this.form.title = this.$t("pages.module.push.notification.new");
            this.showModal(this.$refs.notificationModal);
        },
        showRecord(record){
            this.axios.get(this.endpoints['module_push_notification']+ '/' + record.id).then(response => {
                if(response.data.status) {
                    let data = response.data.data;
                    this.form.data = data;

                    if(data.push_data.image_id) {
                        this.axios.get(this.endpoints['file']+ '/' + data.push_data.image_id).then(response => {
                            if(response.data.status){
                                let data = response.data.data;
                                this.form.data.image = data;

                                this.showModal(this.$refs.notificationDetailModal);
                            }
                        });
                    } else {
                        this.showModal(this.$refs.notificationDetailModal);
                    }

                }
            });
        },
        onSubmit() {
            this.$refs.notificationForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    if (this.image.uploadList.length > 0) {
                        this.submitFormWithUpload();
                    } else {
                        this.submitForm();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload() {
            this.form.loading = true;

            const formData = new FormData();

            this.image.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.form.data.push_data.image_id = data[0].id;
                    this.image.uploadList = [];
                    this.submitForm();
                }, undefined, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            }).finally(() => {
                this.form.loading = false;
            });
        },
        submitForm(){
            let formData = this.prepareFormData();
            this.form.loading = true;

            this.axios.post(this.endpoints['module_push_notification'], formData).then(response => {
                this.onResponse(response.data, () => {
                    this.refreshTable();
                    this.hideModal(this.$refs.notificationModal);
                });
            }).catch(error => {
                error.response.data.message = this.$t('pages.module.push.notification.responseMessages.' + error.response.data.message, error.response.data.message);

                this.onResponseFailure(error.response.data);
            }).finally(() => {
                this.form.loading = false;
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("module/push/notification/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        prepareFormData(){
            let formData = this.cloneData(this.form.data);
            return formData;
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newRecord();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("module/push/notification/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/push/notification/get", {
                page: pagination,
                filterData: Object.assign({
                    sort: 'created_at:desc',
                }, filterData)
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        }
    }
}
</script>

<style scoped>

</style>